<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import TabPersonalInfo from "@/views/pages/tutor/tabs-personal-info";
import TabServices from "@/views/pages/tutor/tabs-services";
import TabQualification from "@/views/pages/tutor/tabs-qualifications";

export default {
	components: {
		Layout,
		PageHeader,
		ConfirmationModal,
		MessageModal,
		TabPersonalInfo,
		TabServices,
		TabQualification
	},
	data() {
		const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

		return {
			id: id,
			title: `${modeName} Service Professional`,
			modeName,
			items: [
				{
					text: "Service Professional"
				},
				{
					text: `${modeName}`,
					active: true,
				},
			],
			data: {
				name: '',
				nric: '',
				gender: '',
				phone: '',
				email: '',
				dob: '',
				age: '',
				application_status: '',
				nationality: '',
				special_needs_exp: '',
				services: {},
				service_providers: {},
				docs: {},
				profile_pic: [],
				edu_qualification: [],
				specialised_cert: [],
				service_details: {},
				remarks: '',
				reside_in_sg: '',
				highest_education_qualification: '',
				is_blacklisted: '',
			},
			serviceOptions: [],
			serviceProviderOptions: [],
			errors: {},
			activeTab: 0,
		};
	},
	async mounted() {
		this.retrieveServices().then((services) => {
			this.serviceOptions = services.map((service) => ({
				name: service.name,
				value: service.id,
			}));
		});

		this.retrieveServiceProviders().then((service_providers) => {
			this.serviceProviderOptions = service_providers.map((service_provider) => ({
				name: service_provider.name,
				value: service_provider.id,
			}));
		});

		if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/tutor/${this.id}`, {
                headers: authHeader(),
            });

            if (result && result.data['code'] === 'success') {
                const data = result.data['data']['tutor'];
                for (const [key, item] of Object.entries(data)) {
                    if (key in this.data) {
                        this.data[key] = item;
                    }

					if (key == 'docs') {
						item.forEach(doc => {
							if (doc.for == 'profile_pic') {
								this.data['profile_pic'].push(doc);
							} else if (doc.for == 'edu_qualification') {
								this.data['edu_qualification'].push(doc);
							} else if (doc.for == 'specialised_cert') {
								this.data['specialised_cert'].push(doc);
							}
						});
					}
                }
            }

			console.log('data', this.data)
        }
	},
	methods: {
		handleTabChange(tabIndex) {
			this.activeTab = tabIndex;
		},
		async retrieveServices() {
            this.loading = true;
            let services = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/service`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const serviceDetails = result['data'].services;
                    services = serviceDetails.filter(service => service.status == 1);
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return services;
        },

		async retrieveServiceProviders() {
            this.loading = true;
            let service_providers = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/service_provider`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const serviceProviderDetails = result['data'].service_providers;
                    service_providers = serviceProviderDetails.filter(provider => provider.status == 1);
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return service_providers;
        },
	}
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<ConfirmationModal ref="confirmationModal" />
		<MessageModal ref="messageModal" />

		<b-tabs v-model="activeTab">
			<b-tab title="Personal Info">
				<TabPersonalInfo :tutorInfo="this.data" />
			</b-tab>

			<b-tab title="Services">
				<TabServices @go-to-previous="handleTabChange(0)" :tutorInfo="this.data" :serviceOptions="serviceOptions" :serviceProviderOptions="serviceProviderOptions" />
			</b-tab>

			<b-tab title="Qualification & Experience">
				<TabQualification @go-to-first-tab="handleTabChange(0)" @go-to-previous="handleTabChange(1)" :tutorInfo="this.data" :serviceOptions="serviceOptions" />
			</b-tab>
		</b-tabs>
	</Layout>
</template>